<template>
  <div class="logcof-continer">
    <div class="logcof-top">
      <div class="top-add">
        <el-button type="primary" size="mini"  @click="dialogFormVisible = true">新增类型</el-button>
        <el-dialog width="35%" title="新增类型"  :visible.sync="dialogFormVisible">
            <div class="but-add">
              <el-form :label-position="labelPosition" label-width="110px" :model="formLabelAlign">
  <el-form-item label="取消类型 :">
    <el-col :span="12">
    <el-input size="mini" v-model="formLabelAlign.name"></el-input>
    </el-col>
  </el-form-item>
  <el-form-item label="输入类型 :">
    <el-col :span="12">
    <el-select v-model="value" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
    </el-col>
  </el-form-item>
  <el-form-item label="输入排序序号 :">
    <el-col :span="12">
    <el-input size="mini" v-model="formLabelAlign.type"></el-input>
    </el-col>
  </el-form-item>
</el-form>
    <div class="but-left">
    <el-button size="mini" type="primary" @click="addFn()">提交</el-button>
    </div>
      <div class="but-right">
    <el-button size="mini" type="info"  @click="dialogFormVisible = false">关闭</el-button>
    </div>
            </div>
  <div slot="footer" class="dialog-footer">

  </div>
</el-dialog>
      </div>
      <!-- <div class="top-select">
        <el-select slot="prepend" v-model="select" size="mini">
          <el-option label="禁用" value="1" />
           <el-option label="启用" value="2" />
            <el-option label="删除" value="3" />
        </el-select>
      </div> -->
      <!-- <div class="top-add">
        <el-button type="success" size="mini">确定</el-button>
      </div> -->
      <div class="top-derive">
        <!--<a :href="url">
 <el-button size="mini" >导出表格</el-button>
        </a>-->  
      </div>
    </div>
    <div class="logcof-center">
      <el-table
      border
        height="450"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="取消类型" width="120">
        </el-table-column>
           <el-table-column prop="reason" label="内容" width="120">
        </el-table-column>
        <el-table-column prop="inputTypeName" label="输入类型" show-overflow-tooltip>
        </el-table-column>
          <el-table-column prop="code" label="ID" show-overflow-tooltip>
        </el-table-column>
          <el-table-column prop="sortIndex" label="排序" width="50" show-overflow-tooltip>
        </el-table-column>
          <el-table-column prop="enable" label="是否启用" show-overflow-tooltip>
            <template slot-scope="scope">
             <el-switch
              v-model="scope.row.enable"
               @change="handleStatusChange(scope.row)"
            >
            </el-switch>
             </template>
        </el-table-column>
          <el-table-column prop="postTime" label="添加时间" show-overflow-tooltip>
        </el-table-column>
         <el-table-column
      fixed="right"
      label="操作"
      width="170">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small" >编辑</el-button>
        <el-button type="text" size="small" v-show="scope.row.levelIndex===1" @click="addhand(scope.$index,scope.row)">添加原因</el-button>
        <el-button type="text" size="small"   @click.native.prevent="deleteRow(scope.$index, scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
      </el-table>
      <el-dialog title="编辑类型" :visible.sync="dialogTableVisible">
          <div class="cempile">
             <el-form :model="form">
    <el-form-item label="取消类型 :" :label-width="formLabelWidth">
      <el-input size="mini" v-model="form.name" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="输入类型 :" :label-width="formLabelWidth">
      <el-select size="mini" v-model="form.region" placeholder="请选择输入类型">
        <el-option label="下拉列表" :value="1"></el-option>
        <el-option label="文本框" :value="2"></el-option>
      </el-select>
    </el-form-item>
     <el-form-item label="输入排列序号 :" :label-width="formLabelWidth">
      <el-input  size="mini"  v-model="form.num" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
          </div>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogTableVisible = false">关闭</el-button>
    <el-button type="primary" @click="typeadd()">提交</el-button>
  </div>
</el-dialog>
  <el-dialog :title="text" :visible.sync="adddialogVisible">
          <div class="cempile">
             <el-form :model="form2">
    <el-form-item label="取消类型 :" :label-width="formLabelWidth">
      <el-input size="mini" v-model="form2.name" :disabled="true" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="取消原因 :" :label-width="formLabelWidth">
   <el-input size="mini" v-model="form2.region" autocomplete="off"></el-input>
    </el-form-item>
     <el-form-item label="输入排列序号 :" :label-width="formLabelWidth">
      <el-input  size="mini"  v-model="form2.date1" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
          </div>
  <div slot="footer" class="dialog-footer">
    <el-button @click="adddialogVisible = false">关闭</el-button>
    <el-button type="primary" @click="tj()">提交</el-button>
  </div>
</el-dialog>
    </div>
    <!-- <div class="fen">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage2"
      :page-sizes="[5, 10,15, 20]"
      :page-size="100"
      layout="sizes, prev, pager, next"
      :total="total">
    </el-pagination>
    </div> -->
  </div>
</template>

<script>
import {wGetList,wSetEnable,wAddOrEdit,wDelete,Export,GetDetail} from '../../api/logconfig'
export default {
  data() {
    return {
      total:0,//总条数
      tableData: [],
      multipleSelection: [],
       dialogTableVisible: false,//编辑类型
        dialogFormVisible: false,//新增
        adddialogVisible: false,//添加原因
         currentPage2: 1,
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          num:Number(),
        },
         form2: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          num:Number(),
        },
        formLabelWidth: '120px',
         labelPosition: 'right',
        formLabelAlign: {
          name: '',
          region: '',
          type: Number(),
        },
          options: [{
          value: 1,
          label: '下拉列表'
        }, {
          value: 2,
          label: '文本框'
        }],
        value: '',
        select:0,//状态
        pagesize:5,//每页几条
        parentId:Number(),//父id
        // index:0,//添加原因当前下标
        text:'添加原因',
        typeid:Number(),//取消类型主键id
        addid:Number(),//添加原因主键id
        bjid:Number(),//编辑原因主键id
        url:'',//导出表格地址
    };
  },

  methods: {
    //取消类型编辑
    typeadd(){
      let data={
        levelIndex:1,
        cancelTypeId:this.typeid,
        name:this.form.name,
        inputType:this.form.region,
        sortIndex:this.form.num
      }
      wAddOrEdit(data).then(res=>{
        console.log(res)
        if(res.code===10000){
          this.getpzlist()
          this.dialogTableVisible=false
        }else{
          this.$message(res.msg)
        }
      })
    },
    //移除
     deleteRow(index, rows) {
        console.log(rows. cancelTypeId)
         wDelete({ id:rows.cancelTypeId}).then(res=>{
          console.log(res)
          if (res.code === 10000){
           this.$message('删除成功');
              this.getpzlist()
          }else{
             this.$message('删除失败');
          }
        }).catch(err=>{
          console.log(err)
        })
      },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //编辑
    handleClick(val){
      // console.log(val.levelIndex)
      if(val.levelIndex===1){
        this.form.name=val.name,
          this.form.region=val.inputType,
          this.form.num=val.sortIndex
    this.dialogTableVisible=true  //打开编辑取消类型弹窗
    this.typeid=val.cancelTypeId
      }else{
            this.form2.name=val.name,
          this.form2.region=val.reason,
          this.form2.date1=val.sortIndex
         this.adddialogVisible=true  //打开编辑原因弹窗
         this.text="编辑原因"
        //  console.log(val.cancelTypeId)
        this.bjid=val. cancelTypeId
      }
       
    },
    //添加原因
    addhand(index,val){
      // this.index=index
      // console.log(this.index)
      // console.log(val)
      this.text="添加原因"
       this.form2.name=val.name
       this.addid=val.cancelTypeId
 this.adddialogVisible=true
//  this.parentId=val.parentId
    },
      // handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      //   this.pagesize=val
      //     this.getpzlist()
      // },
      // handleCurrentChange(val) {
      //   console.log(`当前页: ${val}`);
      //   this.currentPage2=val
      //     this.getpzlist()
      // },
      //获取列表
      getpzlist(){
        let data={}
          wGetList(data).then(res=>{
            console.log(res)
            if(res.code===10000){
                this.tableData=res.data
                // this.total=res.total
            }
          }).catch(err=>{
              console.log(err)
          })
      },
      //是否启用
      handleStatusChange(val){
          console.log(val.enable)
          let data={
            id:val.cancelTypeId,
            enable:val.enable
          }
          wSetEnable(data).then(res=>{
            console.log(res)
            if(res.code===10000){
            this.$message("操作成功")
            }
          }).catch(err=>{
            console.log(err)
          })
      },
      //新增
      addFn(){
        let data={
                levelIndex:1,
               parentId:0,
               name:this.formLabelAlign.name,
               sortIndex:this.formLabelAlign.type,
               inputType:Number(this.value)
        }
        console.log(data);
          wAddOrEdit(data).then(res=>{
            console.log(res)
            if(res.code===10000){
               this.getpzlist()
              this.dialogFormVisible=false
              this.formLabelAlign={}
              this.value=""
                   this.$message(res.msg)
            }else{
              this.$message(res.msg)
            }
          })
      },
      //添加原因
      tj(){
        if(this.text==="添加原因"){
          let data={
          name:this.form2.name,
          cancelTypeId:0,
           parentId:this.addid,
          reason:this.form2.region,
          sortIndex:Number(this.form2.date1),
          levelIndex:2
        }
        wAddOrEdit(data).then(res=>{
          console.log(res)
          if(res.code===10000){
              this.$message("添加原因成功")
              this.getpzlist()
               this.adddialogVisible=false
               this.form2={}
          }else{
            this.$message(res.msg)
          }
        })
       
        }else if(this.text==="编辑原因"){
           let data={
          // name:this.form2.name,
          cancelTypeId:this.bjid,
          reason:this.form2.region,
          sortIndex:Number(this.form2.date1),
          levelIndex:2
        }
        wAddOrEdit(data).then(res=>{
          console.log(res)
          if(res.code===10000){
              this.$message("编辑原因成功")
              this.getpzlist()
               this.adddialogVisible=false
          }
        }).catch(err=>{
          console.log(err)
        })
        }
      
      },
      //导出
      exccelFn(){
            Export().then(res=>{
              // console.log(res)
            if(res.code===10000){
              this.url=res.data
              console.log(res.data)
            }
            }).catch(err=>{
              console.log(err)
            })
      }
  },
  created(){
    this.getpzlist()
    this.exccelFn()
  }
};
</script>

<style scoped>
.logcof-continer {
  width: 100%;
  background: white;
  /* padding: 10px 20px 0 20px; */
  /* box-sizing: border-box; */
}
.logcof-top {
  width: 100%;
  height: 100px;
  margin-top: 10px;
}
.top-add {
  margin-top: 30px;
  margin-left: 30px;
  float: left;
}
.top-select {
  width: 100px;
  float: left;
  margin-top: 30px;
  margin-left: 30px;
}
.top-derive {
  float: right;
  margin-top: 60px;
  margin-right: 30px;
}
.but-add{
  width: 100%;
  height: 100%;
  border-top: 1px solid black;
  padding-left: 50px;
  box-sizing: border-box;
}
.but-left{
  float: left;
  margin-left: 20px;
}
.but-right{
  float: right;
  margin-right: 20px;
}
.cempile{
  width: 53%;
  margin: auto;
}
.fen{
  margin-top: 20px;
}
/deep/ .el-select>.el-input{
  width: 100px;
}
</style>
